import React from 'react';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import { Grid } from '@material-ui/core';

/**
 * # Provided APIs Tab Content
 * It Only shows ProvidedApisCard
 */
export const ProvidedApiTab = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={12}>
      <EntityProvidedApisCard />
    </Grid>
  </Grid>
);

/**
 * # Consumed APIs Tab Content
 * It Only shows ConsumedApisCard
 */
export const ConsumedApiTab = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={12}>
      <EntityConsumedApisCard />
    </Grid>
  </Grid>
);

/**
 * # Provided and Consumed APIs Tab Content
 * It shows ProvidedApisCard and ConsumedApisCard
 */
export const BothApisTab = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={12}>
      <EntityProvidedApisCard />
    </Grid>
    <Grid item md={12}>
      <EntityConsumedApisCard />
    </Grid>
  </Grid>
);
