import { useState } from 'react';

interface CORE_URL {
  NON_PROD: string;
  PROD: string;
  LOCAL: string;
}
const ENV_URL: CORE_URL = {
  NON_PROD: 'https://e1-khatu.apinaranja.com/khatu-core/gql',
  PROD: 'https://khatu.apinaranja.com/khatu-core/gql',
  LOCAL: 'http://localhost:9000/gql',
};

interface RunQueryParams {
  query: string;
  variables?: Record<string, any>;
}

export const useCoreQuery = (env: keyof CORE_URL) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const runQuery = async <T extends unknown>(
    props: RunQueryParams,
  ): Promise<T | undefined> => {
    try {
      setLoading(true);
      const token = window.localStorage.getItem('KhatuToken');
      const festResp = await fetch(ENV_URL[env], {
        method: 'POST',
        body: JSON.stringify(props),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (festResp.ok) {
        const json = await festResp.json();
        return json as T;
      }
      setError(festResp.statusText ?? 'no-status-text-found');
      return undefined;
    } catch (e: any) {
      setError(e.message);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    clearError: () => setError(undefined),
    runQuery,
  };
};
