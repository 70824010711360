import { configApiRef, createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { khatuCoreApiRef, KhatuCoreApiRef } from './api/KhatuCoreApiRef';

export const accountsApprovalPanelPlugin = createPlugin({
  id: 'aws-accounts',
  apis: [
    {
      api: khatuCoreApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }: any) => {
        const apiUrl = configApi.getString('khatuCore.url');
        return new KhatuCoreApiRef(apiUrl);
      },
    },
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const AccountsApprovalPanelPage = accountsApprovalPanelPlugin.provide(
  createRoutableExtension({
    name: 'AccountsApprovalPanelPage',
    component: () =>
      import('./components/AccountsApprovalComponent').then(m => m.AccountsApprovalComponent),
    mountPoint: rootRouteRef,
  }),
);
