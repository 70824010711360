import {
  createPlugin,
  createRoutableExtension,
  createApiFactory,
  configApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {
  AzureGptApi,
  azureGptApiRef,
  BackupS3Api,
  backupS3ApiRef,
} from './api';
import { AzureGptApiProps } from './interfaces/azureGptApi';

export const khatuGptPlugin = createPlugin({
  id: 'khatu-gpt',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: azureGptApiRef,
      deps: { discoveryApi: configApiRef },
      factory: ({ discoveryApi }) => {
        const props = discoveryApi.get<AzureGptApiProps>('azureGpt');
        return new AzureGptApi(props);
      },
    }),
    createApiFactory({
      api: backupS3ApiRef,
      deps: { discoveryApi: configApiRef },
      factory: () => {
        return new BackupS3Api();
      },
    }),
  ],
});

export const KhatuGptPage = khatuGptPlugin.provide(
  createRoutableExtension({
    name: 'KhatuGptPage',
    component: () =>
      import('./components/root').then(m => m.RootLayoutComponent),
    mountPoint: rootRouteRef,
  }),
);
