import { createApiRef } from '@backstage/core-plugin-api';

export const k6ApiRef = createApiRef<K6Api>({
  id: 'plugin.k6.service',
});

export type K6Api = {
  getReportList: (params: any) => Promise<{contents: S3Object[]}>;
  getContent: (key: string) => Promise<{content: string}>;
};

export interface S3Object {
  Key: string;
  LastModified: Date;
  ETag?: string;
  Size?: number;
  StorageClass?: string;
}