/* eslint-disable */
import { createApiRef } from '@backstage/core-plugin-api';

export class KhatuGroupApiRef {
  constructor(public url: string) {}

  public async query(body: Record<string, any>) {
    const item = window.localStorage.getItem('KhatuToken');

    return fetch(`${this.url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: item ? `Bearer ${item}` : '',
      },
    });
  }
}

export const khatuGroupApiRef = createApiRef<KhatuGroupApiRef>({
  id: 'plugin.khatu-groups.service',
});
