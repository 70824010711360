import React from 'react';

const LogoIcon = () => {

  return (
    <img width="80" height="70" src="/logo-icon.png" alt="" />
  );
};

export default LogoIcon;
