import React from 'react';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityLayout,
  EntityLinksCard,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { Button, Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';

const SystemOverviewContent: React.FC = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12}>
        <EntityAboutCard variant="flex" />
      </Grid>
      <Grid item xs={12}>
        <EntityCatalogGraphCard variant="flex" height={400} />
      </Grid>
    </Grid>
  );
};

const ChildrenContent: React.FC = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={12}>
        <EntityHasComponentsCard variant="flex" />
      </Grid>
      <Grid item md={12}>
        <EntityHasApisCard variant="flex" />
      </Grid>
      <Grid item md={12}>
        <EntityHasResourcesCard variant="flex" />
      </Grid>
    </Grid>
  );
};

const GoldenRulesWarning: React.FC = () => {
  return (
    <Card>
      <CardHeader title="Golden Rules not Found" />
      <CardContent>
        This system has no associated golden rules. Read the documentation and
        set values for each of them.
      </CardContent>
      <CardContent>
        <Button
          variant="contained"
          href="https://naranjafintech.atlassian.net/wiki/spaces/SONQO/pages/527537799436/Golden+Rules"
          target="_blank"
        >
          See More
        </Button>
      </CardContent>
    </Card>
  );
};

const SystemGoldenRulesContent: React.FC = () => {
  const { entity } = useEntity();
  return (
    <Grid container>
      {!entity.metadata.links && (
        <Grid item xs={12}>
          <GoldenRulesWarning />
        </Grid>
      )}
      {entity.metadata.links && (
        <Grid md={12}>
          <EntityLinksCard />
        </Grid>
      )}
    </Grid>
  );
};

export const SystemPage: React.FC = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route
        children={<SystemOverviewContent />}
        path="/"
        title="Overview"
      />
      <EntityLayout.Route
        children={<ChildrenContent />}
        path="/children"
        title="Children"
      />
      <EntityLayout.Route
        children={<SystemGoldenRulesContent />}
        path="/golden-rules"
        title="Golden Rules"
      />
    </EntityLayout>
  );
};
