import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRouteRef,
} from '@backstage/core-plugin-api';
import { K6Client, k6ApiRef } from './api';

export const entityContentRouteRef = createRouteRef({
  id: 'K6 reports',
});

export const k6Plugin = createPlugin({
  id: 'k6',
  apis: [
    createApiFactory({
      api: k6ApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }: any) =>
        new K6Client(configApi.getString('backend.baseUrl')),
    }),
  ],
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const EntityK6OverviewCard: () => JSX.Element = k6Plugin.provide(
  createComponentExtension({
    name: 'EntityK6OverviewCard',
    component: {
      lazy: () =>
        import('./components/K6Overview/K6OverviewWidget').then(
          m => m.K6OverviewWidget,
        ),
    },
  }),
);
