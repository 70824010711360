import { createApiRef } from '@backstage/core-plugin-api';
import {
  AzureGPTBody,
  AzureGptApiProps,
  PluginError,
  AzureGptResponse,
  AzureGPTResponseBody,
} from './interfaces/azureGptApi';

export class AzureGptApi {
  private readonly gptUrl!: string;
  private readonly apiKey!: string;
  private readonly first_prompt!: string;
  private readonly temperature!: number;
  private readonly top_p!: number;
  private readonly max_tokens!: number;

  constructor({
    apiKey,
    apiVersion,
    url,
    max_tokens,
    temperature,
    top_p,
  }: AzureGptApiProps) {
    this.gptUrl = `${url}?api-version=${apiVersion}`;
    this.apiKey = apiKey;

    this.max_tokens = parseFloat(String(max_tokens ?? 1500));
    this.temperature = parseFloat(String(temperature ?? 0.2));
    this.top_p = parseFloat(String(top_p ?? 0.95));

    this.first_prompt = `
    Eres un asistente de Naranja X llamado Khatu-GPT. Tu tarea es exclusivamente responder preguntas relacionadas con programación y código. A continuación, se detallan las pautas que debes seguir:
    1. Alcance de tus respuestas:
        - Responde solo preguntas relacionadas con programación y desarrollo de software.
        - Las preguntas pueden incluir temas como sintaxis de codigo, estructuras de datos, desarrollo web, bases de datos, manejo de APIs, herramientas de desarrollo como Git y Docker, y cualquier otra consulta específica sobre programación en.
    2. Temas que no debes cubrir:
        - No respondas preguntas no relacionadas a programación.
        - Ignora preguntas sobre temas personales, noticias, opiniones, o cualquier otra área fuera de la programación.
    3. Formato de preguntas:
        - Para obtener respuestas precisas, solicita a los usuarios que formulen preguntas claras y específicas.
        - Anima a los usuarios a incluir fragmentos de código o detalles específicos del problema que están enfrentando.
    4. Ejemplos de preguntas adecuadas:
        - "¿Cómo puedo crear una función en Python que sume dos números?"
        - "¿Cómo se usa una lista en JavaScript?"
        - "¿Cómo puedo conectar una aplicación Flask a una base de datos SQL?"
    5. Ejemplos de preguntas no adecuadas:
        - "¿Qué opinas sobre el clima de hoy?"
        - "¿Puedes contarme un chiste?"
        - "¿Cuál es tu película favorita?"
    Recuerda que tu objetivo es ayudar a los usuarios a resolver problemas de programación.
    `;
  }

  private makeBody({ messages }: AzureGPTBody): AzureGPTResponseBody {
    return {
      max_tokens: this.max_tokens,
      messages: [
        {
          content: this.first_prompt,
          role: 'user',
        },
        ...messages.map(({ timestamp, ...message }) => ({ ...message })),
      ],
      top_p: this.top_p,
      temperature: this.temperature,
    };
  }

  public async ask(data: AzureGPTBody): Promise<AzureGptResponse | never> {
    const body = this.makeBody(data);
    const response = await fetch(this.gptUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'api-key': this.apiKey,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok)
      throw new PluginError('FetchError', response.statusText, response.status);
    const json: AzureGptResponse = await response.json();
    return json;
  }
}

export class BackupS3Api {
  /**
   * This integration is for testing only.
   */
  private readonly e1_url: string =
    'https://e1-khatu.apinaranja.com/khatu-core/gql';
  constructor() {}
  public async sendQuery<T extends unknown>(body: {
    query: string;
    variables?: Record<string, any>;
  }) {
    const item = window.localStorage.getItem('KhatuToken');
    const response = await fetch(this.e1_url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: item ? `Bearer ${item}` : '',
      },
    });

    if (!response.ok)
      throw new PluginError(
        'S3HandlerError',
        response.statusText,
        response.status,
      );
    const json: T = await response.json();
    console.log(json);
    return json;
  }
}

export const azureGptApiRef = createApiRef<AzureGptApi>({
  id: 'plugin.azure.gpt',
});

export const backupS3ApiRef = createApiRef<BackupS3Api>({
  id: 'plugin.backup.s3',
});
