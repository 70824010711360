import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { EmptyState } from '@backstage/core-components';
import { EntityK6OverviewCard, isK6Available } from '@internal/plugin-k6';

const K6MissingAnnotation = (
  <EmptyState
    title="K6 annotation missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable K6 for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/wiki/x/bgCO7no"
        target="_blank"
      >
        Read more
      </Button>
    }
  />
);

/**
 * # K6 Tab content
 * @returns JSX.Element
 */
export const K6Tab = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isK6Available(e))}>
        <Grid item xs={12}>
          <EntityK6OverviewCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>{K6MissingAnnotation}</EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
