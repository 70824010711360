import { Entity } from '@backstage/catalog-model';

export const AWS_LAMBDA_ANNOTATION = 'aws.com/lambda-function-name';
export const AWS_LAMBDA_REGION_ANNOTATION = 'aws.com/lambda-region';
export const AWS_ACCOUNT_ID = 'aws.com/account-id';
export const useServiceEntityAnnotations = (entity: Entity) => {
  const lambdaName =
    entity?.metadata.annotations?.[AWS_LAMBDA_ANNOTATION] ?? '';
  const lambdaRegion =
    entity?.metadata.annotations?.[AWS_LAMBDA_REGION_ANNOTATION] ?? '';
  const projectName =
    entity?.metadata.annotations?.['github.com/project-slug'] ?? '';
  const accountId = entity?.metadata.annotations?.[AWS_ACCOUNT_ID] ?? '';
  return {
    projectName,
    lambdaName,
    lambdaRegion,
    accountId,
  };
};
