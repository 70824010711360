import AWS from 'aws-sdk';
import { AwsLambdaApi } from './AWSLambdaApi';
import { LambdaData } from '../types';

async function generateCredentials(
  backendUrl: string,
  options: {
    token: string | undefined;
  },
  roleArn: string | undefined,
) {
  const respData = await fetch(`${backendUrl}/api/aws/credentials`, {
    method: 'POST',
    headers: {
      // Disable eqeqeq rule for next line to allow it to pick up both undefind and null
      // eslint-disable-next-line eqeqeq
      ...((options == null ? void 0 : options.token) && {
        Authorization: `Bearer ${options === null ? void 0 : options.token}`,
      }),
      'Content-Type': 'application/json',
    },
    body: roleArn === null ? void 0 : JSON.stringify({ RoleArn: roleArn }),
  });
  try {
    const resp = await respData.json();
    return new AWS.Credentials({
      accessKeyId: resp.AccessKeyId,
      secretAccessKey: resp.SecretAccessKey,
      sessionToken: resp.SessionToken,
    });
  } catch (e: any) {
    throw new Error('MissingBackendAwsAuthException');
  }
}
export class AwsLambdaClient implements AwsLambdaApi {
  async getFunctionByName({
    awsRegion,
    backendUrl,
    functionName,
    token,
    roleArn,
  }: {
    awsRegion: string;
    backendUrl: string;
    functionName: string;
    token?: string;
    roleArn?: string | undefined;
  }): Promise<LambdaData> {
    AWS.config.region = awsRegion;
    AWS.config.credentials = await generateCredentials(
      backendUrl,
      { token },
      roleArn,
    );
    const lambdaApi = new AWS.Lambda({});
    const resp = await lambdaApi
      .getFunction({
        FunctionName: functionName,
      })
      .promise();
    const v = resp.Configuration!;
    return {
      codeSize: v.CodeSize!,
      description: v.Description!,
      functionName: v.FunctionName!,
      lastModifiedDate: v.LastModified!,
      runtime: v.Runtime!,
      memory: v.MemorySize!,
      region: awsRegion,
      state: v.State!,
      lastUpdateStatus: v.LastUpdateStatus!,
      lastUpdateStatusReason: v.LastUpdateStatusReason!,
    };
  }

  async getFunctionByNames({
    awsRegion,
    backendUrl,
    functionNames,
    token,
    roleArn,
  }: {
    awsRegion: string;
    backendUrl: string;
    functionNames: string[];
    token?: string;
    roleArn?: string | undefined;
  }): Promise<LambdaData[]> {
    AWS.config.region = awsRegion;
    AWS.config.credentials = await generateCredentials(
      backendUrl,
      { token },
      roleArn,
    );

    const lambdaApi = new AWS.Lambda({});
    const resps = Promise.all(
      functionNames.map(async funName => {
        const resp = await lambdaApi
          .getFunction({
            FunctionName: funName,
          })
          .promise();
        const v = resp.Configuration!;
        return {
          codeSize: v.CodeSize!,
          description: v.Description!,
          functionName: v.FunctionName!,
          lastModifiedDate: v.LastModified!,
          runtime: v.Runtime!,
          memory: v.MemorySize!,
          region: awsRegion,
          state: v.State!,
          lastUpdateStatus: v.LastUpdateStatus!,
          lastUpdateStatusReason: v.LastUpdateStatusReason!,
        };
      }),
    );
    return resps;
  }
}
