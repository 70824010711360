import { configApiRef, createApiFactory, createComponentExtension, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { NthunderApi, nthunderApiRef } from './api';

export { isNthunderAvailable } from './hooks/userEntityAnnotations';

export const nthunderPlugin = createPlugin({
  id: 'nthunder',
  apis: [
    createApiFactory({
      api: nthunderApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }: any) => new NthunderApi(configApi.getString('backend.baseUrl')),
    })
  ],
  routes: {
    root: rootRouteRef,
  },
});

// export const NthunderComponent = nthunderPlugin.provide(
//   createRoutableExtension({
//     name: 'NthunderComponent',
//     component: () =>
//       import('./components/NthunderComponent').then(m => m.NthunderComponent),
//     mountPoint: rootRouteRef,
//   }),
// );

export const NthunderComponent: () => JSX.Element = nthunderPlugin.provide(
  createComponentExtension({
    name: 'NthunderComponent',
    component: {
      lazy: () =>
        import('./components/NthunderComponent/NthunderComponent').then(
          m => m.NthunderComponent,
        ),
    },
  }),
);