import { createApiRef } from '@backstage/core-plugin-api';

export class AdminPanelApiRef {
  constructor(public url: string) {}

  async updateUser(userId: string, userRole: string[], userGitlabId: number) {
    const item = window.localStorage.getItem('KhatuToken');
    const mutation = `
      mutation UPDATE_USER($userInput: UserInput!) {
        updateUser(userInput: $userInput) {
          _id
          roles
          gitlab_id
        }
      }
    `;
    
    const variables = {
      userInput: {
        _id: userId,
        roles: userRole,
        gitlab_id: userGitlabId
      },
    };
    
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`,
      },
      body: JSON.stringify({ query: mutation, variables }),
    };
    
    const response = await fetch(this.url, request);
    if (!response.ok) {
      throw new Error('Error HTTP');
    }
    const responseBody = await response.json();
    return responseBody;
  }
    
  async getUsers() {
    const item = window.localStorage.getItem('KhatuToken');
    const query = `
      query USERS_LIST($after: String) {
        users(after: $after) {
          pageInfo {
            endCursor
            hasNextPage
          }
          node {
            _id
            access_level_name
            access_level_value
            avatar_url
            created_at
            email
            gitlab_id
            name
            roles
            saml_uid
            updated_at
            username
          }
        }
      }
    `;

    const requestUser = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`
      },
      body: JSON.stringify({ query, variables: {} })
    };

    const response = await fetch(this.url, requestUser);
    if (!response.ok) {
      throw new Error('error HTTP');
    }

    const responseBody = await response.json();
    return responseBody;
  }
}

export const adminPanelApiRef = createApiRef<AdminPanelApiRef>({
  id: 'plugin.admin-panel.service',
});
