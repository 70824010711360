import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { isArgocdAvailable, EntityArgoCDHistoryCard } from '@roadiehq/backstage-plugin-argo-cd';

const ArgoAnnotationsMissing = (
  <EmptyState
    title="Argo CD annotations missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable Argo CD for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/wiki/spaces/KHAT/pages/528094986261/Argo+CD+Backstage+Plugin"
        target='_blank'
      >
        Read more
      </Button>
    }
  />
);

export const ArgoCDTab = (
  <Grid container spacing={3}>
    <EntitySwitch>
      <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
        <Grid item xs={12}>
          <EntityArgoCDHistoryCard />
        </Grid>
      </EntitySwitch.Case>
      <EntitySwitch.Case>{ArgoAnnotationsMissing}</EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
