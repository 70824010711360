import { DateTime } from 'luxon';
import { WebStorage } from '@backstage/core-app-api';
import {
  createApiRef,
  DiscoveryApi,
  ErrorApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

const lastSeenKey = 'user_last_seen_date';

export type Category = {
  id: string;
  title: string;
};

export type Report = {
  id: string;
  category?: Category;
  name: string;
  publisher: string;
  created_at: string;
  accessTkn?: string;
};

export type ReportsList = {
  count: number;
  results: Report[];
};

export type CreateReportRequest = Omit<
  Report,
  'category' | 'created_at' | 'name'
> & {
  category?: string;
};

export type CreateCategoryRequest = {
  title: string;
};

export interface Reports {
  reports(opts: {
    max?: number;
    page?: number;
    category?: string;
  }): Promise<ReportsList>;

  reportByID(id: string): Promise<Report>;

  createReport(request: CreateReportRequest): Promise<Report>;

  updateReport(id: string, request: CreateReportRequest): Promise<Report>;

  deleteReportByID(id: string): Promise<void>;

  categories(): Promise<Category[]>;
  createCategory(request: CreateCategoryRequest): Promise<void>;

  lastSeenDate(): DateTime;
  markLastSeenDate(date: DateTime): void;
}

export const reportsApiRef = createApiRef<Reports>({
  id: 'plugin.reports.service',
});

type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  errorApi: ErrorApi;
};

export class ReportsApi implements Reports {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly webStorage: WebStorage;

  constructor(opts: Options) {
    this.discoveryApi = opts.discoveryApi;
    this.identityApi = opts.identityApi;
    this.webStorage = new WebStorage('reports', opts.errorApi);
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('reports');
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = new Headers(init?.headers);
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${baseApiUrl}${input}`, {
      ...init,
      headers,
    });

    return fetch(request).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }

      return response.json() as Promise<T>;
    });
  }

  private async delete(input: string, init?: RequestInit): Promise<void> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('reports');
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = new Headers(init?.headers);
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${baseApiUrl}${input}`, {
      ...{ method: 'DELETE' },
      headers,
    });

    return fetch(request).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }
    });
  }

  async reports({
    max,
    page,
    category,
  }: {
    max?: number;
    page?: number;
    category?: string;
  }): Promise<ReportsList> {
    const params = new URLSearchParams();
    if (category) {
      params.append('category', category);
    }
    if (max) {
      params.append('max', max.toString());
    }
    if (page) {
      params.append('page', page.toString());
    }

    return this.fetch<ReportsList>(`/reports?${params.toString()}`);
  }

  async reportByID(id: string): Promise<Report> {
    return this.fetch<Report>(`/reports/${id}`);
  }

  async createReport(request: CreateReportRequest): Promise<Report> {
    return await this.fetch<Report>(`/reports`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
  }

  async updateReport(
    id: string,
    request: CreateReportRequest,
  ): Promise<Report> {
    return this.fetch<Report>(`/reports/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
  }

  async deleteReportByID(id: string): Promise<void> {
    return this.delete(`/reports/${id}`, { method: 'DELETE' });
  }

  async categories(): Promise<Category[]> {
    return this.fetch<Category[]>('/categories');
  }

  async createCategory(request: CreateCategoryRequest): Promise<void> {
    await this.fetch<Category>(`/categories`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
  }

  lastSeenDate(): DateTime {
    const lastSeen = this.webStorage.get<string>(lastSeenKey);
    if (!lastSeen) {
      // magic default date, probably enough in the past to consider every reports as "not seen"
      return DateTime.fromISO('1990-01-01');
    }

    return DateTime.fromISO(lastSeen);
  }

  markLastSeenDate(date: DateTime): void {
    this.webStorage.set<string>(lastSeenKey, date.toISO()!);
  }
}
