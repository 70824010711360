/* eslint-disable */
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
// import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';
import { EntityValidationPage } from '@backstage/plugin-entity-validation';
import {
  CustomDarkThemeProvider,
  CustomLightThemeProvider,
} from './theme/CustomTheme';
import {
  discoveryApiRef,
  IdentityApi,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { FlushcachePage } from '@internal/plugin-flushcache';
import { setTokenCookie } from './cookieAuth';
import { OpsgeniePage } from '@internal/plugin-opsgenie';
import { KhatuGroupsPage } from '@internal/plugin-khatu-groups';
// additional packages/app/src/App.tsx from a create-app deployment
import { AdminPanelPage } from '@internal/plugin-admin-panel';
import { GroupPanelPage } from '@internal/plugin-group-panel';
// custom scaffolder extension
import { ValidateGitlabGroupFieldExtension } from './scaffolder/GitlabGroupPicker';

import { ReportsPage } from '@internal/plugin-reports';
import {
  AccountsApprovalPanelPage,
  ReviewersTeams,
} from '@internal/plugin-aws-accounts-panel';
import { AwsAccountsRequestPage } from '@internal/plugin-aws-accounts-request';
import {
  adminPanelAccessPermission,
  awsAccountsBillingPermission,
  awsAccountsCloudopsPermission,
  awsAccountsSecurityPermission,
  restartpodsPermission
} from '@internal/plugin-aws-accounts-common';
import { AccountsAdminPanelPage } from '@internal/plugin-accounts-admin-panel';
import { OpenDoraPluginPage } from '@devoteam-nl/open-dora-backstage-plugin';
import { RdsQueryRunnerPage } from 'backstage-plugin-rds-query-runner';
import { SoftwareCatalogPage } from '@internal/plugin-software-catalog';
import { KhatuGptPage } from '@internal/plugin-khatu-gpt';
// import { TimeSaverPage } from '@tduniec/backstage-plugin-time-saver';
import { TimeSaverPage } from '@internal/backstage-plugin-timesaver-internal';
import { BillingBoardsPage } from '@internal/backstage-plugin-billing-boards';
import { PodAnnotationsPage } from '@internal/backstage-plugin-pod-annotations';

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef).getBaseUrl('cookie');
      return (
        <SignInPage
          {...props}
          providers={[
            {
              id: 'azure-auth-provider',
              title: 'Microsoft Active Directory',
              message: 'Sign in to IDP App using your AAD account',
              apiRef: microsoftAuthApiRef,
            },
          ]}
          title="Bienvenido a Khatu X"
          align="center"
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(await discoveryApi, identityApi);
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'my-dark-theme',
      title: 'Khatu Dark Theme',
      variant: 'dark',
      Provider: CustomDarkThemeProvider,
    },
    {
      id: 'my-light-theme',
      title: 'Khatu Light Theme',
      variant: 'light',
      Provider: CustomLightThemeProvider,
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Recommended',
              filter: entity =>
                entity?.metadata?.tags?.includes('recommended') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <ValidateGitlabGroupFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    {/* <Route path="/create" element={<DisablePage />} /> */}
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    {/* <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    /> */}
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <CatalogImportPage />
         
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/flushcache" element={<FlushcachePage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route
      path="/admin-panel"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <AdminPanelPage />
        </RequirePermission>
      }
    />
    <Route
      path="/group-panel"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <GroupPanelPage />
        </RequirePermission>
      }
    />
    <Route path="/khatu-groups" element={<KhatuGroupsPage />} />
    <Route
      path="/billing-boards" 
      element={
        <BillingBoardsPage />
      } 
    />
    <Route path="/reports" element={<ReportsPage />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route
      path="/aws-accounts-billing-panel"
      element={
        <RequirePermission permission={awsAccountsBillingPermission}>
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.BILLING }} />
        </RequirePermission>
      }
    />
    <Route
      path="/aws-accounts-cloudops-panel"
      element={
        <RequirePermission permission={awsAccountsCloudopsPermission}>
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.CLOUDOPS }} />
        </RequirePermission>
      }
    />
    <Route
      path="/aws-accounts-security-panel"
      element={
        <RequirePermission permission={awsAccountsSecurityPermission}>
          <AccountsApprovalPanelPage {...{ team: ReviewersTeams.SECURITY }} />
        </RequirePermission>
      }
    />
    <Route
      path="/accounts-admin-panel"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <AccountsAdminPanelPage />
        </RequirePermission>
      }
    />
    <Route path="/aws-accounts-request" element={<AwsAccountsRequestPage />} />
    <Route path="/open-dora" 
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <OpenDoraPluginPage />
        </RequirePermission>
      } 
    />
    <Route path="/software-catalog" element={<SoftwareCatalogPage />} />
    <Route path="/rds-query-runner" element={<RdsQueryRunnerPage />} />
    <Route
      path="/time-saver"
      element={
        <RequirePermission permission={adminPanelAccessPermission}>
          <TimeSaverPage />
        </RequirePermission>
      }
    />
    <Route 
      path="/pod-annotations" 
      element={
        <RequirePermission permission={restartpodsPermission}>
          <PodAnnotationsPage />
        </RequirePermission>
      }
    />
    <Route path="/khatu-gpt" element={<KhatuGptPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
