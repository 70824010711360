import { Entity } from '@backstage/catalog-model';

export const NTHUNDER_ANNOTATION = 'nthunder.com/repository-path';
export const useEntityAnnotations = (entity: Entity) => {
  const nthunder = entity?.metadata.annotations?.[NTHUNDER_ANNOTATION] ?? '';
  return {
    nthunder,
  };
};

export const isNthunderAvailable = (entity: Entity) =>
  entity?.metadata.annotations?.[NTHUNDER_ANNOTATION];
