import React from 'react';
import { Typography, Grid, Card, CardHeader, LinearProgress, Tabs, Tab, Box, } from '@material-ui/core';
import {

  ErrorBoundary,
} from '@backstage/core-components';
import {MissingAnnotationEmptyState} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { K6_PROJECT_ID, useServiceEntityAnnotations } from '../../hooks/useServiceEntityAnnotations';
import { useK6 } from '../../hooks/useK6';
import { useEntity } from '@backstage/plugin-catalog-react';
import { k6ApiRef } from '../../api';
import { useApi } from '@backstage/core-plugin-api';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const mystyle = {
  overflow: "auto",
  width: '100%',
  height: '80vh',
};

const K6Overview = ({
  entity,
}: {
  entity: Entity;
}) => {
  const { projectName, projectId } = useServiceEntityAnnotations(entity);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [reports, setReports] = React.useState<string[]>([]);

  const prefix = `${projectName}-${projectId}`;

  const [response] = useK6({
    prefix,
  });

  const k6Api = useApi(k6ApiRef);

  const getReport = async (key: string = '', index = 0) => {
    setLoading(true);
    try {
      if (!reports[index]) {
        const responseData: any = await k6Api.getContent(encodeURIComponent(key));
        reports[index] = responseData.content;
        setReports(reports);
      }
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false);
    }
  }

  let firstReport = '';
  if (response.response?.firstReport) {
    firstReport = response.response?.firstReport
  }
    
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function formatDate(date: Date | undefined) {
    if (date)
      return new Intl.DateTimeFormat('es-Ar', {
        day: '2-digit', month: '2-digit', year: '2-digit',
        hour: '2-digit', minute: '2-digit', second: '2-digit'
      }).format(new Date(date))
    return ''
  }

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (response.response?.contents)
      if (!reports[newValue])
        getReport(response.response?.contents[newValue].Key, newValue);
  };
  return (
    <Card>
    <Grid container spacing={2}>
      <>
        { response.loading ? <Grid item xs={12}>
          <CardHeader title={<Typography variant="h5">K6</Typography>} />
          <LinearProgress />
          </Grid>
        : <Grid item xs={12}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              aria-label="full width tabs example"
            >
              {response.response?.contents?.map((content, index) => 
                <Tab label={formatDate(content.LastModified)} {...a11yProps(index)} />
              )}
            </Tabs>
            {response.response?.contents?.map((_, index) => 
              (<TabPanel value={value} index={index}>
                {
                  loading ? <LinearProgress /> : <div style={{backgroundColor: '#fff'}}>
                    <iframe style={mystyle} title='k6' srcDoc={reports[index] || firstReport } />
                  </div>
                  // loading ? <LinearProgress /> : <div style={mystyle} dangerouslySetInnerHTML={{ __html: reports[index] || firstReport }} />
                }
              </TabPanel>)
            )}
        </Grid> }
      </>
      </Grid>
    </Card>
  )
};

export const isK6Available = (entity: Entity) =>
  entity?.metadata.annotations?.[K6_PROJECT_ID];

export const K6OverviewWidget = () => {
  const { entity } = useEntity();
  return !isK6Available(entity) ? (
    <MissingAnnotationEmptyState annotation={K6_PROJECT_ID} />
  ) : (
    <ErrorBoundary>
      <K6Overview entity={entity} />
    </ErrorBoundary>
  );
};