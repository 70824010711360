import {
  EntityAboutCard,
  EntityLinksCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityHighlightsCard } from '@rsc-labs/backstage-highlights-plugin';
import {
  EntityGitlabReadmeCard,
  isGitlabAvailable,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import { Grid } from '@material-ui/core';
import React from 'react';

/**
 * # Overview Tab Content
 * It Shows AboutCard, LinksCard, CatalogGraphCard and GitlabReadmeCard
 */
export const OverviewTab = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={12} xs={12}>
      <EntityHighlightsCard />
    </Grid>
    <Grid item md={8} xs={12}>
      <EntityAboutCard variant="flex" />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityLinksCard variant="flex" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="flex" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <Grid item md={6} xs={12}>
          <EntityGitlabReadmeCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);
