import { Entity } from '@backstage/catalog-model';

export const K6_PROJECT_ID = 'k6.io/project-id';
export const useServiceEntityAnnotations = (entity: Entity) => {
  const projectName =
    entity?.metadata.name ?? '';
  const projectId = entity?.metadata.annotations?.[K6_PROJECT_ID] ?? '';
  return {
    projectName,
    projectId,
  };
};
