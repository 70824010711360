import React from 'react';
import {
  EntityLayout,
  EntitySwitch,
  isComponentType,
} from '@backstage/plugin-catalog';
// import parts of component
import { TechDocsTab } from '../tabs/TechDocsTab';
import { OverviewTab } from '../tabs/OverviewTab';
import { CiCdTab, GitlabTab } from '../tabs/GitlabTab';
import { DatadogTab } from '../tabs/DatadogTab';
import { JiraTab } from '../tabs/JiraTab';
import { BothApisTab, ConsumedApiTab } from '../tabs/ApiTab';
import { WrongTypeComponentTab } from '../tabs/WrongTypeComponentTab';
import { MyAWSTab } from '../tabs/MyAWSTab';
// import { AWSTab } from '../tabs/AWSTab';
// import { EntityKubernetesContent } from '@backstage/plugin-kubernetes';
import { FlushCacheTab } from '../tabs/FlushCacheTab';
import { NthunderTab } from '../tabs/NthunderTab';
import { LitmusTab } from '../tabs/LitmusTab';
import { K6Tab } from '../tabs/K6Tab';
import { ArgoCDTab } from '../tabs/ArgoTab';
// import { JiraDashboardTab } from '../tabs/JiraDashboardTab';

/**
 * # Contain all common tabs
 * - Overview
 * - TechDocs
 * - CD/CD
 * - Gitlab
 * - Jira
 */
const CommonTabsForAllComponents = (
  <>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd" title="ci/cd">
      {CiCdTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/gitlab" title="gitlab">
      {GitlabTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/jira" title="Jira">
      {JiraTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/datadog" title="Datadog">
      {DatadogTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/flushcache" title="Flush cache">
      {FlushCacheTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/vulnerabilities" title="Vulnerabilities">
      {NthunderTab}
    </EntityLayout.Route>
    <EntityLayout.Route path="/argo-cd" title="Argo CD">
      {ArgoCDTab}
    </EntityLayout.Route>

    {/* <EntityLayout.Route
      path="/jira-dashboard"
      title="Jira Dashboard"
    >
      {JiraDashboardTab}
    </EntityLayout.Route> */}
  </>
);

/**
 * # Layout for Components type Service
 */
const ServiceLayoutContent = (
  <>
    <EntityLayout>
      {CommonTabsForAllComponents}

      <EntityLayout.Route path="/apis" title="Apis">
        {BothApisTab}
      </EntityLayout.Route>

      {/* <EntityLayout.Route path="/aws-lambda" title="Aws lambda">
        {AWSTab}
      </EntityLayout.Route> */}
      {/* <EntityLayout.Route path="/kubernetes" title="Kubernetes">
        <EntityKubernetesContent refreshIntervalMs={30000} />
      </EntityLayout.Route> */}
      <EntityLayout.Route path="/aws-lambda" title="Aws lambda">
        {MyAWSTab}
      </EntityLayout.Route>
      <EntityLayout.Route path="/litmus" title="Litmus">
        {LitmusTab}
      </EntityLayout.Route>
      <EntityLayout.Route path="/k6" title="K6">
        {K6Tab}
      </EntityLayout.Route>
    </EntityLayout>
  </>
);

/**
 * # Layout for Components type Website, library and mobile
 */
const WebsiteLayoutContent = (
  <>
    <EntityLayout>
      {CommonTabsForAllComponents}
      <EntityLayout.Route path="/apis" title="Apis">
        {ConsumedApiTab}
      </EntityLayout.Route>
    </EntityLayout>
  </>
);

/**
 * # Layout for Components type "pelotudo"
 */
const UnknownTypeLayoutContent = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Wrong Type">
      {WrongTypeComponentTab}
    </EntityLayout.Route>
  </EntityLayout>
);

export const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {ServiceLayoutContent}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('website')}>
      {WebsiteLayoutContent}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('library')}>
      {WebsiteLayoutContent}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isComponentType('mobile')}>
      {WebsiteLayoutContent}
    </EntitySwitch.Case>
    <EntitySwitch.Case>{UnknownTypeLayoutContent}</EntitySwitch.Case>
  </EntitySwitch>
);
