import { createApiRef } from '@backstage/core-plugin-api';

export class SoftwareCatalogApi {
  constructor(public url: string) {}

  public query(body: Record<string, any>) {
    const item = window.localStorage.getItem('KhatuToken');
    return fetch(this.url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: item ? `Bearer ${item}` : '',
      },
    });
  }
}

export const softwareCatalogApiRef = createApiRef<SoftwareCatalogApi>({
  id: 'plugin.softwarecatalog.service',
});
