import {
  configApiRef,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { adminPanelApiRef, AdminPanelApiRef} from './api/AdminPanelApiRef';

export const adminPanelPlugin = createPlugin({
  id: 'admin-panel',
  apis: [
    {
      api: adminPanelApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }: any) => {
        const apiUrl = configApi.getString('khatuCore.url');
        return new AdminPanelApiRef(apiUrl);
      }
    }
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const AdminPanelPage = adminPanelPlugin.provide(
  createRoutableExtension({
    name: 'AdminPanelPage',
    component: () =>
      import('./components/PanelAdminComponent').then(m => m.PanelAdminComponent),
    mountPoint: rootRouteRef,
  }),
);
