import { createApiRef } from '@backstage/core-plugin-api';
import { Answers } from '../components/utils/CreateAccount';

export class AccountsRequestApiRef {
  constructor(public url: string) {}

  async getGroups() {
    const item = window.localStorage.getItem('KhatuToken');
    const query = `
      query GROUP_LIST($full_path:String!) {
        groups(full_path:$full_path) {
          pageInfo{
            endCursor
            hasNextPage
          }
          node{
            _id
            gitlab_id
            name
            full_path
            group_status
            min_access_to_grant
          }
        }
      }
    `;

    const variables = {
      full_path: 'naranja-digital',
    };

    const requestUser = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${item}`,
      },
      body: JSON.stringify({ query, variables }),
    };
    const response = await fetch(this.url, requestUser);
    if (!response.ok) {
      throw new Error('error HTTP');
    }

    const responseBody = await response.json();
    return responseBody;
  }

  async getUser() {
    const token = window.localStorage.getItem('KhatuToken');
    const query = `{
      userAuthorizationRole {
        _id
        roles
      }
    }`;

    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ query, variables: {} }),
    };

    const response = await fetch(this.url, request);
    if (!response.ok) {
      throw new Error('Error HTTP');
    }
    const responseBody = await response.json();
    return responseBody;
  }

  async getAccountsByGroup(groupId: string) {
    const token = window.localStorage.getItem('KhatuToken');
    const query = `
      query ACCOUNTS_BY_GROUP($group_id: ID!) {
        accountsRelatedToAGroup(group_id: $group_id){
          node{
            _id
            account_id
            name
            email
            environment
            status
            requested_by
          }
        }
      }
    `;

    const variables = { group_id: groupId };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ query, variables }),
    };

    try {
      const response = await fetch(this.url, requestOptions);
      if (!response.ok) {
        throw new Error('Failed to fetch accounts by group');
      }
      const responseBody = await response.json();
      return responseBody.data.accountsRelatedToAGroup.node;
    } catch (error) {
      throw new Error(`Error fetching accounts by group: ${error}`);
    }
  }

  async getMyGroups() {
    const token = window.localStorage.getItem('KhatuToken');
    const query = `
      query MY_GROUPS($after: String) {
        groupsUserBelongsTo(after: $after) {
          pageInfo {
            endCursor
            hasNextPage
          }
          node {
            _id
            access_management_emails
            created_at
            description
            full_path
            gitlab_id
            group_status
            min_access_to_grant
            name
            owner_email
            updated_at
            access_status
          }
        }
      }
    `;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ query, variables: {} }),
    };

    try {
      const response = await fetch(this.url, requestOptions);
      if (!response.ok) {
        throw new Error('Failed to fetch groups user belongs to');
      }
      const responseBody = await response.json();
      return responseBody.data.groupsUserBelongsTo.node;
    } catch (error) {
      throw new Error(`Error fetching groups user belongs to: ${error}`);
    }
  }

  async requestAccounts(
    projectId: string,
    environment: string[],
    answers: Answers,
  ) {
    const token = window.localStorage.getItem('KhatuToken');
    const mutation = `
      mutation REQUEST_ACCOUNTS($params: RequestAwsAccountInput!) {
        requestAccount(params: $params) {
          node {
            _id
            name
            environment
          }
        }
      }
    `;

    const variables = {
      params: {
        group_id: projectId,
        environments: environment,
        a1: answers.a1,
        a2: answers.a2,
        a3: answers.a3,
        a4: answers.a4,
        a5: answers.a5,
      },
    };

    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ query: mutation, variables }),
    };

    const response = await fetch(this.url, request);
    if (!response.ok) {
      throw new Error('Error HTTP');
    }
    const responseBody = await response.json();
    return responseBody;
  }
}

export const accountsRequestApiRef = createApiRef<AccountsRequestApiRef>({
  id: 'plugin.aws-accounts-request.service',
});
