import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  errorApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { reportsRouteRef } from './routes';
import { ReportsApi, reportsApiRef } from './api';

export const reportsPlugin = createPlugin({
  id: 'reports',
  routes: {
    root: reportsRouteRef,
  },
  apis: [
    createApiFactory({
      api: reportsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
        errorApi: errorApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, identityApi, errorApi }) => {
        return new ReportsApi({
          discoveryApi: discoveryApi,
          identityApi: identityApi,
          errorApi: errorApi,
        });
      },
    }),
  ],
});

export const ReportsPage = reportsPlugin.provide(
  createRoutableExtension({
    name: 'ReportsPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: reportsRouteRef,
  }),
);
