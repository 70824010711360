import React from 'react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { opsgenieApiRef } from '../../api';
import { Team } from '../../types';
import { CreateAlertForm } from './CreateAlertForm';

type TeamOption = {
  value: string;
  label: string;
};

export const CreatAlert = () => {
  const opsgenieApi = useApi(opsgenieApiRef);

  const { value, loading, error } = useAsync(async (): Promise<Team[]> => {
    return opsgenieApi.getTeams();
  }, []);

  if (loading) return <Progress />;
  if (error) return <ResponseErrorPanel error={error} />;

  const teamOptions = value?.map(
    aTeam => ({ value: aTeam.id, label: aTeam.name } as TeamOption),
  );

  return <CreateAlertForm props={{ teamOptions: teamOptions || [] }} />;
};
