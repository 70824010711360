import React from 'react';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { Grid, Button } from '@material-ui/core';
import {
  isJiraAvailable,
  EntityJiraOverviewCard,
} from '@roadiehq/backstage-plugin-jira';

const JiraAnnotationsMissing = (
  <EmptyState
    title="Jira annotations missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable Jira for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/wiki/spaces/KHAT/pages/527590719664/Conectar+con+Jira"
        target='_blank'
      >
        Read more
      </Button>
    }
  />
);

/**
 * # Jira Tab Content
 * @see https://roadie.io/backstage/plugins/jira/
 */
export const JiraTab = (
  <EntitySwitch>
    <EntitySwitch.Case if={isJiraAvailable}>
      <Grid item xs={12}>
        <EntityJiraOverviewCard />
      </Grid>
    </EntitySwitch.Case>
    <EntitySwitch.Case>{JiraAnnotationsMissing}</EntitySwitch.Case>
  </EntitySwitch>
);
