import {
  configApiRef,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { khatuGroupApiRef, KhatuGroupApiRef } from './api/KhatuGroupApiRef';

export const khatuGroupsPlugin = createPlugin({
  id: 'khatu-groups',
  apis: [
    {
      api: khatuGroupApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }: any) => {
        const apiUrl = configApi.getString('khatuCore.url');
        return new KhatuGroupApiRef(apiUrl);
      },
    },
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const KhatuGroupsPage = khatuGroupsPlugin.provide(
  createRoutableExtension({
    name: 'KhatuGroupsPage',
    component: () =>
      import('./components/PluginComponent').then(m => m.GitlabPluginComponent),
    mountPoint: rootRouteRef,
  }),
);
