import { createApiRef } from '@backstage/core-plugin-api';
import { RestartError, RestartErrorName } from './types/Error';

interface ResponseCluster {
  list: string[];
}

interface ResponseService {
  cluster_name: string;
  list: string[];
}

type SendRestartProps = {
  clusterName: string;
  serviceName: string;
};

type ResponseSendRestartItem = {
  allowed: boolean;
  cluster_name: string;
  id: string;
  port: string;
  servicio: string;
};

type ResponseSendRestartBody = {
  item: ResponseSendRestartItem;
  message: string;
};

type ResponseSendRestart = {
  body: ResponseSendRestartBody;
};

export class PodAnnotationApi {
  private readonly bearerToken!: string | undefined;
  constructor(private readonly base_url: string) {
    const token = window.localStorage.getItem('KhatuToken');
    if (token !== null) {
      this.bearerToken = `Bearer ${token}`;
    }
  }

  public async fetchCustterList(): Promise<ResponseCluster | never> {
    try {
      const response = await fetch(`${this.base_url}/clusters-list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: this.bearerToken ?? '',
        },
      });
      if (response.ok) {
        return response.json();
      } else {
        throw new RestartError(
          response.statusText,
          response.status,
          RestartErrorName.fetchCluserErrorName,
        );
      }
    } catch (error) {
      throw RestartError.instance(error);
    }
  }

  public async fetchServicesList(
    clusterName: string,
  ): Promise<ResponseService | never> {
    try {
      const response = await fetch(
        `${this.base_url}/services-by-cluster/${clusterName}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: this.bearerToken ?? '',
          },
        },
      );
      if (response.ok) {
        return response.json();
      } else {
        throw new RestartError(
          response.statusText,
          response.status,
          RestartErrorName.fetchCluserErrorName,
        );
      }
    } catch (error) {
      throw RestartError.instance(error);
    }
  }

  public async sendRestart(
    props: SendRestartProps,
  ): Promise<ResponseSendRestart | never> {
    try {
      const response = await fetch(`${this.base_url}`, {
        method: 'POST',
        body: JSON.stringify(props),
        headers: {
          'Content-Type': 'application/json',
          authorization: this.bearerToken ?? '',
        },
      });
      if (response.ok) {
        return response.json();
      } else {
        throw new RestartError(
          response.statusText,
          response.status,
          RestartErrorName.SubmitRestartErrorName,
        );
      }
    } catch (error) {
      throw RestartError.instance(error);
    }
  }
}

export const podAnnotationApiRef = createApiRef<PodAnnotationApi>({
  id: 'pod.annotations.plugin',
});
