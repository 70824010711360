import React from 'react';
import { EmptyState } from '@backstage/core-components';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

export const WrongTypeComponentTab = (
  <Grid container>
    <Grid item md={8} xs={12}>
      <EmptyState
        title="Component specification type is wrong"
        missing="data"
        description="Please check golden rules documentation, and set a correct spec.type for this component"
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://naranjafintech.atlassian.net/wiki/spaces/arquitectura/pages/527332507849/Software+Catalog#Component-catalog-info.yaml-(en-la-ra%C3%ADz-de-cada-repositorio)"
            target='_blank'
          >
            Read more
          </Button>
        }
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <Card>
        <CardHeader title="Allowed values" />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary="service"
                secondary="Any piece of Software designed to support machine-to-machine interaction, over a network, in an interoperable way"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="website"
                secondary="Any piece of software deployed at a specific Internet address, accessed via a browser"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="mobile"
                secondary="Any piece software developed (with native programming language or not) to be run on mobile operating systems such as Android and iOS."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="library"
                secondary="Any piece of software that aims to make programming easier by providing common functionality"
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);
