export enum RestartErrorName {
  fetchCluserErrorName = 'FETCH_CLUSTERS',
  fetchServiceErrorName = 'FETCH_SERVICES',
  SubmitRestartErrorName = 'SUBMIT_RESTART',
  UnknowErrorName = 'UNKNOW',
}
export class RestartError extends Error {
  public readonly status!: number;
  constructor(message: string, status: number, name: string) {
    super(message);
    this.status = status;
    this.name = name;
  }

  public static instance(error: any) {
    if (error instanceof RestartError) {
      return new RestartError(error.message, error.status, error.name);
    } else {
      return new RestartError(error.message, 500, 'UNKNOW');
    }
  }
}
