import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const reportsRouteRef = createRouteRef({
  id: 'reports',
});

export const reportViewRouteRef = createSubRouteRef({
  id: 'reports/view',
  path: '/view/:id',
  parent: reportsRouteRef,
});

export const reportCreateRouteRef = createSubRouteRef({
  id: 'reports/create',
  path: '/create',
  parent: reportsRouteRef,
});

export const categoriesListRouteRef = createSubRouteRef({
  id: 'reports/categories',
  path: '/categories',
  parent: reportsRouteRef,
});

export const googleReportViewRouteRef = createSubRouteRef({
  id: 'reports/google',
  path: '/google',
  parent: reportsRouteRef,
});
