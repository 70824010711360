import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export type RdsInstance = {
  DBClusterArn?: string;
  DBClusterIdentifier?: string;
  DBName?: string;
  Status?: string;
  Engine?: string;
  EngineVersion?: string;
  databases?: Array<string>;
};

type RdsApiOptions = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

export type SqlQueryOutput = {
  columns: Array<string>;
  rows: Array<any>;
  fullRowCount: number;
};

export class RdsQueryRunnerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(opts: RdsApiOptions) {
    this.discoveryApi = opts.discoveryApi;
    this.identityApi = opts.identityApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    const baseApiUrl = await this.discoveryApi.getBaseUrl('rds-query-runner');
    const { token } = await this.identityApi.getCredentials();

    const headers: HeadersInit = new Headers(init?.headers);
    if (token && !headers.has('authorization')) {
      headers.set('authorization', `Bearer ${token}`);
    }

    const request = new Request(`${baseApiUrl}${input}`, {
      ...init,
      headers,
    });

    return fetch(request).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }

      return response.json() as Promise<T>;
    });
  }

  async findDatabases(
    accountId: number,
    rdsIdentifier: string,
  ): Promise<string[]> {
    return await this.fetch(
      `/awsAccounts/${accountId}/rds/${rdsIdentifier}/databases`,
    );
  }

  async findRdsByAccount(accountId: number): Promise<RdsInstance[]> {
    return await this.fetch(`/awsAccounts/${accountId}/rds`);
  }

  async getAwsAccounts(): Promise<Array<any>> {
    return await this.fetch(`/awsAccounts/`);
  }

  async executeQuery(
    awsAccountId: number,
    dBInstanceIdentifier: string,
    schema: string,
    command: string,
  ): Promise<SqlQueryOutput> {
    return await this.fetch<SqlQueryOutput>(
      `/${awsAccountId}/${dBInstanceIdentifier}/${schema}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ command: command }),
      },
    );
  }
}

export const rdsQueryRunnerApiRef = createApiRef<RdsQueryRunnerApi>({
  id: 'plugin.rds-query-runner.service',
});
