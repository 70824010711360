import React, { PropsWithChildren, useEffect, useState } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LinkIcon from '@material-ui/icons/Link';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {useSearchModal, SearchModal} from '@backstage/plugin-search';
import {
  Link,
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Dashboard from '@material-ui/icons/Dashboard';
import GitHub from '@material-ui/icons/GitHub';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { RequirePermission } from '@backstage/plugin-permission-react';
// eslint-disable-next-line no-restricted-imports
import { SvgIconComponent } from '@material-ui/icons';
import { SupportAgent } from '@mui/icons-material';
import BuildIcon from '@material-ui/icons/Build';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudCircleIcon from '@material-ui/icons/CloudCircle';
import {
  adminPanelAccessPermission,
  awsAccountsBillingPermission,
  awsAccountsCloudopsPermission,
  awsAccountsSecurityPermission,
  restartpodsPermission
} from '@internal/plugin-aws-accounts-common';
import CodeIcon from '@material-ui/icons/Code';
import ArchiveIcon from '@mui/icons-material/Archive';
import Timelapse from '@material-ui/icons/Timelapse';
import PollIcon from '@material-ui/icons/Poll';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import { useLocation } from 'react-router-dom';
import LoopIcon from '@material-ui/icons/Loop';

const useSidebarLogoStyles = makeStyles(theme => ({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
  },
  sidebarItem: {
    width: '100%',
    '& span': {
      fontWeight: theme.palette.type === 'dark' ? '500' : 'bold'
    }
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  typography: {
    cursor: 'pointer',
    padding: '5px 5px',
  },
  icon: {
    margin: '0 10px 0 8px',
  },
}));

// const openWindowByURL = (url: string) => {
//   return window.open(url, '_blank');
// };

const UpdateIsOpen = ({ setState }: { setState: Function }) => {
  const { isOpen } = useSidebarOpenState();
  setState(isOpen);

  return <></>;
};

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const SearchSidebarItem = () => {
  const location = useLocation();
  const classes = useSidebarLogoStyles();
  const searchModal = useSearchModal();

  const isInSearchPage = location.pathname === '/search';

  return (<>
      <SidebarItem className={classes.sidebarItem} icon={SearchIcon} text="Search" 
        to={isInSearchPage ? "/search" : undefined} 
        onClick={ isInSearchPage ? 
          () => {} :
          () => searchModal.toggleModal()
        }
      /> 
      {!searchModal.state.hidden && <SearchModal toggleModal={() => searchModal.toggleModal()}/>}
  </>)

}

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const classes = useSidebarLogoStyles();
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {}, [isOpen]);

  return (
    <SidebarPage>
      <Sidebar>
        <UpdateIsOpen setState={setIsOpen} />
        <SidebarLogo />
        <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
          <SearchSidebarItem/>
        </SidebarGroup>
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem className={classes.sidebarItem} icon={HomeIcon} to="catalog" text="Home" />
          <SidebarItem className={classes.sidebarItem} icon={ExtensionIcon} to="api-docs" text="APIs" />
          <SidebarItem className={classes.sidebarItem} icon={LibraryBooks} to="docs" text="Docs" />
          <SidebarItem className={classes.sidebarItem} icon={CodeIcon} to="create" text="Templates" />
          <SidebarItem className={classes.sidebarItem} icon={AccessibilityNewIcon} to="khatu-gpt" text="Khatu GPT" />
          <SidebarItem 
            className={classes.sidebarItem} 
            icon={AnnouncementIcon}
            to="announcements"
            text="Announcements"
          />
          <SidebarItem className={classes.sidebarItem} icon={LocalLibrary} to="opsgenie" text="Opsgenie" />
          <SidebarItem
            className={classes.sidebarItem}
            icon={SupportAgent as SvgIconComponent}
            to="opsgenie/create-alert"
            text="On Call"
          />
          <SidebarItem className={classes.sidebarItem} icon={GitHub} to="khatu-groups" text="Groups" />
          <SidebarItem icon={PollIcon} to="billing-boards" text="Billing Boards" />
          <SidebarItem className={classes.sidebarItem} icon={Dashboard} to="reports" text="Reports" />
          <SidebarItem
            className={classes.sidebarItem}
            icon={BuildIcon}
            to="entity-validation"
            text="Validator"
          />

          <SidebarItem
            className={classes.sidebarItem}
            icon={CloudUploadIcon}
            to="aws-accounts-request"
            text="Cloud Accounts"
          />

          <RequirePermission
            permission={restartpodsPermission}
            errorPage={<></>}
          >
            <SidebarItem
              className={classes.sidebarItem}
              icon={LoopIcon}
              to="pod-annotations"
              text="Restart Pods"
            />
          </RequirePermission>

          <RequirePermission
            permission={awsAccountsBillingPermission}
            errorPage={<></>}
          >
            <SidebarItem
              className={classes.sidebarItem}
              icon={CloudIcon as SvgIconComponent}
              to="/aws-accounts-billing-panel"
              text="Aws Accounts Billing Panel"
            />
          </RequirePermission>
          <RequirePermission
            permission={awsAccountsCloudopsPermission}
            errorPage={<></>}
          >
            <SidebarItem
              className={classes.sidebarItem}
              icon={CloudIcon as SvgIconComponent}
              to="/aws-accounts-cloudops-panel"
              text="Aws Accounts Cloudops Panel"
            />
          </RequirePermission>
          <RequirePermission
            permission={awsAccountsSecurityPermission}
            errorPage={<></>}
          >
            <SidebarItem
              className={classes.sidebarItem}
              icon={CloudIcon as SvgIconComponent}
              to="/aws-accounts-security-panel"
              text="Aws Accounts Security Panel"
            />
          </RequirePermission>

          <SidebarItem
            className={classes.sidebarItem}
            icon={ArchiveIcon as SvgIconComponent}
            to="rds-query-runner"
            text="Consultas SQL"
          />

          <RequirePermission
            permission={adminPanelAccessPermission}
            errorPage={<></>}
          >
            <SidebarScrollWrapper>
              {!isOpen && <SidebarItem icon={LinkIcon} to="" text="" />}
              {isOpen && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <LinkIcon className={classes.icon} />
                    <Typography className={classes.sidebarItem}>
                      Admin &hellip;
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <SidebarItem
                      className={classes.sidebarItem}
                      icon={SupervisorAccountIcon}
                      to="admin-panel"
                      text="Admin Panel"
                    />
                    <SidebarItem
                      className={classes.sidebarItem}
                      icon={SupervisedUserCircleIcon}
                      to="group-panel"
                      text="Group Panel"
                    />
                    <SidebarItem
                      className={classes.sidebarItem}
                      icon={CloudCircleIcon}
                      to="accounts-admin-panel"
                      text="Accounts Panel"
                    />
                    <SidebarItem
                      className={classes.sidebarItem}
                      icon={Timelapse}
                      to="time-saver"
                      text="timeSaver"
                    />
                    <SidebarItem icon={Dashboard} to="open-dora" text="DORA Metrics" />
                  </AccordionDetails>
                </Accordion>
              )}
            </SidebarScrollWrapper>
          </RequirePermission>
          <SidebarScrollWrapper />
          {/* End global nav */}
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
