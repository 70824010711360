export type TeamOption = {
  value: string;
  label: string;
};

export type CreateAlertFormProps = {
  teamOptions: TeamOption[];
};


export enum ORGANIZATIONAL_UNIT {
  CARD = 'tarjeta',
  FINANCIAL = 'financiera',
}
