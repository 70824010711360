import React from 'react';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { Grid, Button } from '@material-ui/core';
import {
  // isDatadogGraphAvailable,
  // EntityDatadogGraphCard,
  EntityDatadogContent,
} from '@roadiehq/backstage-plugin-datadog';
import { Entity } from '@backstage/catalog-model';

function isDatadogGraphAvailable(entity: Entity): boolean {
  if (entity.metadata.annotations === undefined) return false;
  if (entity.metadata.annotations['datadoghq.com/dashboard-url'] === undefined)
    return false;
  return true;
}

const DataDogMissingAnnotation = (
  <EmptyState
    title="Data Dog annotation missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable Datadog for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/wiki/spaces/KHAT/pages/527586263055/Agregar+Datadog"
        target="_blank"
      >
        Read more
      </Button>
    }
  />
);

/**
 * # Datadog tab Content
 * @see https://roadie.io/backstage/plugins/datadog/
 */
export const DatadogTab = (
  <EntitySwitch>
    <EntitySwitch.Case if={isDatadogGraphAvailable}>
      <Grid item md={12}>
        <EntityDatadogContent />
      </Grid>
    </EntitySwitch.Case>
    <EntitySwitch.Case>{DataDogMissingAnnotation}</EntitySwitch.Case>
  </EntitySwitch>
);
