/* eslint-disable */
import { createApiRef } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

export class KhatuCoreApiRef {
  constructor(public url: string) {}

  public async query<T = any>(
    body: Record<string, any>,
  ): Promise<T | ResponseError> {
    const item = window.localStorage.getItem('KhatuToken');
    return fetch(`${this.url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        authorization: item ? `Bearer ${item}` : '',
      },
    }).then(async response => {
      if (!response.ok) {
        throw await ResponseError.fromResponse(response);
      }
      return response.json() as Promise<T>;
    });
  }
}

export const khatuCoreApiRef = createApiRef<KhatuCoreApiRef>({
  id: 'plugin.aws-accounts.service',
});
