import React from 'react';
import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  isGitlabAvailable,
  EntityGitlabContent,
  EntityGitlabPipelinesTable,
  EntityGitlabReleasesCard,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import { Button, Grid } from '@material-ui/core';
import { EmptyState } from '@backstage/core-components';

const GitalbMissingAnnotation = (
  <EmptyState
    title="Gitlab annotation missing"
    missing="info"
    description="You need to add an annotation to your component if you want to enable Gitlab for it. You can read more about annotations in Khatu-X by clicking the button below."
    action={
      <Button
        variant="contained"
        color="primary"
        href="https://naranjafintech.atlassian.net/wiki/spaces/KHAT/pages/527591309591/Gitlab"
        target='_blank'
      >
        Read more
      </Button>
    }
  />
);

/**
 * # Gitlab Tab Content
 * It shows EntityGitlabContent
 * @see https://github.com/immobiliare/backstage-plugin-gitlab
 *
 */
export const GitlabTab = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityGitlabContent />
        </Grid>
      </Grid>
    </EntitySwitch.Case>
    <EntitySwitch.Case>{GitalbMissingAnnotation}</EntitySwitch.Case>
  </EntitySwitch>
);

/**
 * # CI/CD Tab Content
 * It shows GitlabPipelinesTable and GitlabReleasesCard
 * @see https://github.com/immobiliare/backstage-plugin-gitlab
 *
 */
export const CiCdTab = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <EntityGitlabPipelinesTable />
        </Grid>
        <Grid item md={12}>
          <EntityGitlabReleasesCard />
        </Grid>
      </Grid>
    </EntitySwitch.Case>
    <EntitySwitch.Case>{GitalbMissingAnnotation}</EntitySwitch.Case>
  </EntitySwitch>
);
