// import { Config } from '@backstage/config';

import { createApiRef } from "@backstage/core-plugin-api";

export class FetchError extends Error {
  get name(): string {
    return this.constructor.name;
  }

  static async forResponse(resp: Response): Promise<FetchError> {
    return new FetchError(
      `Request failed with status code ${
        resp.status
      }.\nReason: ${await resp.text()}`,
    );
  }
}

export class CloudflareApi {
  // static fromConfig(config: Config) {
  //   return new CloudflareApi(config.getString('lighthouse.baseUrl'));
  // }
  // private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
  //   const resp = await fetch(`${this.url}${input}`, init);
  //   if (!resp.ok) throw await FetchError.forResponse(resp);
  //   return await resp.json();
  // }
  constructor(public url: string) {}

  async purgeCache(body: any) {
    return fetch(`${this.url}/api/proxy/flushcache`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
  }
}

export const cloudflareApiRef = createApiRef<CloudflareApi>({
  id: 'plugin.flushcache.service',
});