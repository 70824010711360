import { K6Api, S3Object } from './K6Api';

export class K6Client implements K6Api {

  constructor(public url: string) {}

  async getReportList({
    prefix,
  }: any): Promise<{contents: S3Object[]}> {
    const respData = await fetch(`${this.url}/api/k6/list/${prefix}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    try {
      const resp = await respData.json();
      return resp;
    } catch (e: any) {
      throw new Error('Error');
    }
  }

  async getContent(key: string) {
    const respData = await fetch(`${this.url}/api/k6/report/${key}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    try {
      const resp = await respData.json() as {content: string};
      return resp;
    } catch (e: any) {
      throw new Error('Error');
    }
  }
}
