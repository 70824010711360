import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
// como cofiguro el cors en un servidor flask?
import { rootRouteRef } from './routes';
import { PodAnnotationApi, podAnnotationApiRef } from './api';

export const podAnnotationsPlugin = createPlugin({
  id: 'pod-annotations',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: podAnnotationApiRef,
      deps: { discoveryApi: configApiRef },
      factory: ({discoveryApi}) => {
        const podAnnotationService = discoveryApi.get<{url: string}>("podAnnotationService")
        
        return new PodAnnotationApi(podAnnotationService.url);
      },
    }),
  ],
});

export const PodAnnotationsPage = podAnnotationsPlugin.provide(
  createRoutableExtension({
    name: 'PodAnnotationsPage',
    component: () =>
      import('./components/mainComponent').then(m => m.PodAnnotationComponent),
    mountPoint: rootRouteRef,
  }),
);
