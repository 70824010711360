import { useAsyncRetry } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { S3Object, k6ApiRef } from '../api';
import { useCallback } from 'react';

export function useK6({ prefix }: { prefix: string }) {
  const k6Api = useApi(k6ApiRef);

  const getReportList = useCallback(
    async () => {
      return await k6Api.getReportList({
        prefix,
      });
    },
    [prefix], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const getReport = useCallback(
    async key => {
      return await k6Api.getContent(key);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const {
    loading,
    value: response,
    error,
    retry,
  } = useAsyncRetry<{
    contents: S3Object[];
    firstReport: string;
  }>(async () => {
    const k6Reports = await getReportList();
    const reports = k6Reports.contents.filter(
      el => (el.Key?.includes('-report.hml') || el.Key?.includes('-report.html')) && !el.Key?.includes('xk6'),
    );
    const firstReport = await getReport(encodeURIComponent(reports[0].Key!));
    return { contents: reports, firstReport: firstReport.content };
  }, []);

  return [
    {
      loading,
      response,
      error,
      retry,
    },
  ] as const;
}
