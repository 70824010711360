import { EntityAboutCard, EntityLayout } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

const GenericEntityOverviewContent: React.FC = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12}>
        <EntityAboutCard
          variant="flex"
          key="DefaultOverviewContent.aboutCard"
        />
      </Grid>
    </Grid>
  );
};

export const GenericEntityPage: React.FC = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route
        children={<GenericEntityOverviewContent />}
        path="/"
        title="Overview"
      />
    </EntityLayout>
  );
};
