import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'billing-boards',
});

export const cudosSsoViewRouteRef = createSubRouteRef({
  id: 'billing-boards/cudos-sso',
  path: '/cudos-sso',
  parent: rootRouteRef,
});

export const cudosFnviewRouteRef = createSubRouteRef({
  id: 'billing-boards/cudos-fn',
  path: '/cudos-fn',
  parent: rootRouteRef,
});

export const taosSsoViewRouteRef = createSubRouteRef({
  id: 'billing-boards/taos-sso',
  path: '/taos-sso',
  parent: rootRouteRef,
});

export const taosFnViewRouteRef = createSubRouteRef({
  id: 'billing-boards/taos-fn',
  path: '/taos-fn',
  parent: rootRouteRef,
});