import React from 'react';
import { makeStyles, Theme, Grid, Paper } from '@material-ui/core';

import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import {
  catalogApiRef,
  CATALOG_FILTER_EXISTS,
} from '@backstage/plugin-catalog-react';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';

import { SearchType } from '@backstage/plugin-search';
import {
  SearchBar,
  SearchFilter,
  SearchResult,
  SearchPagination,
  useSearch,
} from '@backstage/plugin-search-react';
import {
  CatalogIcon,
  Content,
  DocsIcon,
  Header,
  Page,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles((theme: Theme) => ({
  searchbar: {
    backgroundColor: theme.palette.type === 'dark' ? '#333233' : '#FFFFFF',
  },
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '8px'
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
  accordion_box: {
    backgroundColor: theme.palette.type === 'dark' ? '#383838' : '#FFFFFF',
    filter: theme.palette.type === 'dark' ? 'brightness(1)' : 'brightness(1.11)',
    width: '100%', 
    borderRadius: '8px'
  },
}));

const SearchPage = () => {
  const classes = useStyles();
  const { types } = useSearch();
  const catalogApi = useApi(catalogApiRef);

  return (
    <Page themeId="home">
      <Header title="Search" />
      <Content>
        <Grid container direction="row">
          <Grid item container xs={12} alignItems='center' justifyContent='space-between'>
            <Grid item xs={3}>
              <SearchBar className={classes.searchbar} clearButton={false}/>
            </Grid>
            <Grid item xs={9} justifyContent='flex-end'>
              <SearchPagination/>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.accordion_box}>
              <SearchType.Accordion
                name="Result Type"
                defaultValue="software-catalog"
                types={[
                  {
                    value: 'software-catalog',
                    name: 'Software Catalog',
                    icon: <CatalogIcon />,
                  },
                  {
                    value: 'techdocs',
                    name: 'Documentation',
                    icon: <DocsIcon />,
                  },
                ]}
              />
            </div>
            <Paper className={classes.filters}>
              {types.includes('techdocs') && (
                <SearchFilter.Select
                  className={classes.filter}
                  label="Entity"
                  name="name"
                  values={async () => {
                    // Return a list of entities which are documented.
                    const { items } = await catalogApi.getEntities({
                      fields: ['metadata.name'],
                      filter: {
                        'metadata.annotations.backstage.io/techdocs-ref':
                          CATALOG_FILTER_EXISTS,
                      },
                    });

                    const names = items.map(entity => entity.metadata.name);
                    names.sort();
                    return names;
                  }}
                />
              )}
              <SearchFilter.Select
                className={classes.filter}
                label="Kind"
                name="kind"
                values={['Component', 'Template']}
              />
              <SearchFilter.Checkbox
                className={classes.filter}
                label="Lifecycle"
                name="lifecycle"
                values={['experimental', 'production']}
              />
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Paper style={{width: '98%', marginLeft: '1%', borderRadius: '8px'}}>
              <SearchResult>
                <CatalogSearchResultListItem />
                <TechDocsSearchResultListItem />
              </SearchResult>
            </Paper>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const searchPage = <SearchPage />;
