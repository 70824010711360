import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {
  lightTheme,
  darkTheme,
  createTheme,
  genPageTheme,
  shapes,
} from '@backstage/theme';

const khatuLightTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#50007F',
    },
    secondary: {
      main: '#565a6e',
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    navigation: {
      background: '#fff',
      indicator: '#fff',
      color: '#333b',
      selectedColor: '#50007F',
      navItem: {
        hoverBackground: '#fff',
      },
    },
    background: {
      default: '#F5F5F4',
      paper: '#fff',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
  },
  fontFamily: 'Gibson',
  pageTheme: {
    home: genPageTheme({
      colors: ['#fff', '#fff'],
      shape: shapes.wave,
      options: {
        fontColor: '#FE5000',
      },
    }),
  },
});

const khatuDarkTheme = createTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#f5f5f5',
    },
    secondary: {
      main: '#d45500',
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    navigation: {
      background: '#333333',
      indicator: '#fff',
      color: '#f5f5f5',
      selectedColor: '#f5f5f5',
      navItem: {
        hoverBackground: '#1a1a1a',
      },
    },
    background: {
      default: '#1a1a1a',
      paper: '#333333',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
  },
  fontFamily: 'Gibson',
  pageTheme: {
    home: genPageTheme({
      colors: ['#50007F', '#50007F'],
      shape: shapes.wave,
      options: {
        fontColor: '#F5F5F4',
      },
    }),
  },
});

export const CustomLightThemeProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={khatuLightTheme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
};

export const CustomDarkThemeProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={khatuDarkTheme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
};
