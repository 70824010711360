import { useAsync, useAsyncRetry } from 'react-use';
import {
  useApi,
  errorApiRef,
  configApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { LambdaData } from '../types';
import { awsLambdaApiRef } from '../api';
import { useCallback } from 'react';

export function useLambda({
  lambdaName,
  region,
  roleArn,
}: {
  lambdaName: string;
  region: string;
  roleArn: string | undefined;
}) {
  const lambdaApi = useApi(awsLambdaApiRef);
  const errorApi = useApi(errorApiRef);
  const configApi = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const identity = useAsync(async () => {
    return await identityApi.getCredentials();
  });

  const lambdaNames = lambdaName.split(',');
  const getFunctionByName = useCallback(
    async () => {
      return await lambdaApi.getFunctionByNames({
        backendUrl: configApi.getString('backend.baseUrl'),
        awsRegion: region,
        functionNames: lambdaNames,
        token: identity.value?.token || undefined,
        roleArn: roleArn,
      });
    },
    [region, lambdaName], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const {
    loading,
    value: lambda,
    error,
    retry,
  } = useAsyncRetry<LambdaData[] | null>(async () => {
    try {
      const lambdaFunction = await getFunctionByName();
      return lambdaFunction;
    } catch (e) {
      if (e instanceof Error) {
        if (e?.message === 'MissingBackendAwsAuthException') {
          errorApi.post(new Error('Please add aws auth backend plugin'));
          return null;
        }
        errorApi.post(e);
      }
      return null;
    }
  }, []);

  return [
    {
      loading,
      lambda,
      error,
      retry,
    },
  ] as const;
}
