import React from 'react';
import {
  EntityGroupProfileCard,
  // EntityMembersListCard,
  // EntityOwnershipCard,
} from '@backstage/plugin-org';
import { Grid } from '@material-ui/core';
import { EntityLayout } from '@backstage/plugin-catalog';

const GroupPageContent: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <EntityGroupProfileCard variant="flex" />
      </Grid>
      {/* <Grid item xs={12}>
        <EntityOwnershipCard variant="flex" />
      </Grid>
      <Grid item xs={12}>
        <EntityMembersListCard />
      </Grid> */}
    </Grid>
  );
};

export const GroupPage: React.FC = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route
        children={<GroupPageContent />}
        path="/"
        title="Overview"
      />
    </EntityLayout>
  );
};
