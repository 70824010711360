import { configApiRef, createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { AccountsAdminApiRef, accountsAdminApiRef } from './api/AccountsAdminApiRef';

export const accountsAdminPanelPlugin = createPlugin({
  id: 'accounts-admin-panel',
  apis: [
    {
      api: accountsAdminApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }: any ) => {
        const apiUrl = configApi.getString('khatuCore.url');
        return new AccountsAdminApiRef(apiUrl); 
      }
    }
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const AccountsAdminPanelPage = accountsAdminPanelPlugin.provide(
  createRoutableExtension({
    name: 'AccountsAdminPanelPage',
    component: () =>
      import('./components/Layout').then(m => m.Layout),
    mountPoint: rootRouteRef,
  }),
);
