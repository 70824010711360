import {
  configApiRef,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { groupPanelApiRef, GroupPanelApiRef } from './api/GroupPanelApiRef';

export const groupPanelPlugin = createPlugin({
  id: 'group-panel',
  apis: [
    {
      api: groupPanelApiRef,
      deps: { configApi: configApiRef},
      factory: ({ configApi }: any) => {
        const apiUrl = configApi.getString('khatuCore.url');
        return new GroupPanelApiRef(apiUrl);
      }
    },
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const GroupPanelPage = groupPanelPlugin.provide(
  createRoutableExtension({
    name: 'GroupPanelPage',
    component: () =>
      import('./components/PanelGroupComponent').then(m => m.PanelGroupComponent),
    mountPoint: rootRouteRef,
  }),
);
