import React from 'react';
import {
  EntityAboutCard,
  EntityHasSystemsCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { Grid } from '@material-ui/core';

const DomainOverviewContent: React.FC = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12}>
        <EntityAboutCard variant="flex" />
      </Grid>
      <Grid item xs={12}>
        <EntityCatalogGraphCard variant="flex" height={400} />
      </Grid>
    </Grid>
  );
};

const DomainHasSystemsContent: React.FC = () => {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12}>
        <EntityHasSystemsCard variant="flex" />
      </Grid>
    </Grid>
  );
};

export const DomainPage: React.FC = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route
        children={<DomainOverviewContent />}
        path="/"
        title="Overview"
      />
      <EntityLayout.Route
        children={<DomainHasSystemsContent />}
        path="/systems"
        title="Systems"
      />
    </EntityLayout>
  );
};
