import { configApiRef, createApiFactory, createComponentExtension, createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { CloudflareApi, cloudflareApiRef } from './api';

export { isFlushcacheAvailable } from './hooks/useServiceAnnotations';

export const flushcachePlugin = createPlugin({
  id: 'flushcache',
  apis: [
    createApiFactory({
      api: cloudflareApiRef,
      deps: { configApi: configApiRef },
      factory: ({ configApi }: any) => new CloudflareApi(configApi.getString('backend.baseUrl')),
    })
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const FlushcachePage = flushcachePlugin.provide(
  createRoutableExtension({
    name: 'FlushcachePage',
    component: () =>
      import('./components/FlushcacheComponent').then(m => m.FlushcacheComponent),
    mountPoint: rootRouteRef,
  }),
);

export const FlushcacheEntityCard: () => JSX.Element = flushcachePlugin.provide(
  createComponentExtension({
    name: 'FlushcacheEntityCard',
    component: {
      lazy: () =>
        import('./components/FlushcacheEntityCard/FlushcacheEntityCard').then(
          m => m.FlushcacheEntityCard,
        ),
    },
  }),
);