import React from 'react';
import {
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';
import { Grid } from '@material-ui/core';
import { EntityLayout } from '@backstage/plugin-catalog';

const UserPageContent: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <EntityUserProfileCard variant="flex" />
      </Grid>
      <Grid item xs={12} md={6}>
        <EntityOwnershipCard variant="flex" />
      </Grid>
    </Grid>
  );
};

export const UserPage: React.FC = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route
        children={<UserPageContent />}
        path="/"
        title="Overview"
      />
    </EntityLayout>
  );
};
