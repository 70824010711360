/* eslint-disable */
import { createApiRef } from '@backstage/core-plugin-api';

export class AccountsAdminApiRef {
  constructor(public url: string) {}

  async getAccountsInProcess() {
    const item = window.localStorage.getItem('KhatuToken');
    const query = `
        query CREATION_IN_PTOGRESS{
          accountsInProgress{
              node{
                  _id
                  name
                  email
                  environment
                  status
              }
          }
      }
    `;

    const requestUser = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`
      },
      body: JSON.stringify({ query, variables: {} })
    };

    const response = await fetch(this.url, requestUser);
    if (!response.ok) {
      throw new Error('error HTTP');
    }

    const responseBody = await response.json();
    return responseBody;
  }

  async comeBackTo(accountId: string, newStatus: string) {
    const item = window.localStorage.getItem('KhatuToken');
    const mutation = `
      mutation COMEBACK_TO($params: [ComeBackFlowAccountToInput!]!) {
        comeBackFlowAccountTo(params: $params){
            node{
              _id
              name
              status
            }
        }
      }
    `;

    const variables = {
      params: [
        {
          _id: accountId,
          status: newStatus
        }
      ]
    };

    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`
      },
      body: JSON.stringify({ query: mutation, variables })
    };

    const response = await fetch(this.url, request);
    if (!response.ok) {
      const errorMessage = `Error en la solicitud: ${response.statusText} (${response.status})`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    

    const responseBody = await response.json();
    return responseBody;
  }
}

export const accountsAdminApiRef = createApiRef<AccountsAdminApiRef>({
  id: 'plugin.accounts-admin.service',
});
