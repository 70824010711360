import { IdentityApi } from '@backstage/core-plugin-api';
import { KhatuTkn } from './types';

const KHATU_TKN_KEY = 'KhatuToken';

export function getKhatuTkn(): KhatuTkn | null {
  const storageItem = localStorage.getItem(KHATU_TKN_KEY);
  if (storageItem) {
    const parsedKhatuTkn = parseJwt(storageItem);

    const khatuTkn: KhatuTkn = {
      accessTkn: parseJwt(parsedKhatuTkn.$accessToken),
      ...parsedKhatuTkn,
    };

    return khatuTkn;
  }
  return null;
}

// Parses supplied JWT token and returns the payload
function parseJwt(token: string): any {
  let retObj = null;
  if (token !== null && token.trim() !== '') {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(
          c =>
            // eslint-disable-next-line prefer-template
            '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
        )
        .join(''),
    );
    retObj = JSON.parse(jsonPayload);
  }
  return retObj;
}

// Returns milliseconds until the supplied JWT token expires
function msUntilExpiry(token: string): number {
  const payload = parseJwt(token);
  const remaining =
    new Date(payload.exp * 1000).getTime() - new Date().getTime();
  return remaining;
}

// Calls the specified url regularly using an auth token to set a token cookie
// to authorize regular HTTP requests when loading techdocs
export async function setTokenCookie(url: string, identityApi: IdentityApi) {
  const { token } = await identityApi.getCredentials();
  if (!token) {
    return;
  }

  await fetch(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  localStorage.setItem(KHATU_TKN_KEY, token);

  // Call this function again a few minutes before the token expires
  const ms = msUntilExpiry(token) - 4 * 60 * 1000;
  setTimeout(
    () => {
      setTokenCookie(url, identityApi);
    },
    ms > 0 ? ms : 10000,
  );
}
