import React from 'react';
import { Grid } from '@material-ui/core';
import {
  EntityAboutCard,
  EntityLayout,
  EntityLinksCard,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityApiDefinitionCard,
  EntityConsumingComponentsCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';

const ApiOverviewContent: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item md={8} xs={12}>
        <EntityAboutCard variant='flex'/>
      </Grid>
      <Grid item md={4} xs={12}>
        <EntityLinksCard variant='flex' />
      </Grid>
      <Grid item xs={12}>
        <EntityCatalogGraphCard variant="flex" height={400} />
      </Grid>
      <Grid container item md={12}>
        <Grid item md={6}>
          <EntityProvidingComponentsCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumingComponentsCard />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ApiDefinitionContent: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <EntityApiDefinitionCard />
      </Grid>
    </Grid>
  );
};

export const ApiPage: React.FC = () => {
  return (
    <EntityLayout>
      <EntityLayout.Route
        children={<ApiOverviewContent />}
        path="/"
        title="Overview"
      />
      <EntityLayout.Route
        children={<ApiDefinitionContent />}
        path="/definition"
        title="Definition"
      />
    </EntityLayout>
  );
};
