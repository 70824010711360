export interface AzureGptResponse {
  choices: Choice[];
  created: number;
  id: string;
  model: string;
  object: string;
  prompt_filter_results: PromptFilterResult[];
  system_fingerprint: string;
  usage: Usage;
}

export interface Choice {
  content_filter_results: ContentFilterResults;
  finish_reason: string;
  index: number;
  logprobs: null;
  message: Message;
}

export interface ContentFilterResults {
  hate: Hate;
  self_harm: Hate;
  sexual: Hate;
  violence: Hate;
}

export interface Hate {
  filtered: boolean;
  severity: string;
}

export interface Message {
  content: string;
  role: 'user' | 'assistant';
  timestamp: number;
}

export interface PromptFilterResult {
  prompt_index: number;
  content_filter_results: ContentFilterResults;
}

export interface Usage {
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
}

export interface AzureGPTBody {
  messages: Message[];
}

export interface AzureGPTResponseBody {
  temperature: number;
  top_p: number;
  max_tokens: number;
  messages: Array<Pick<Message, 'content' | 'role'>>;
}

export interface AzureGptApiProps {
  url: string;
  apiVersion: string;
  apiKey: string;
  temperature?: number;
  top_p?: number;
  max_tokens?: number;
}

export class PluginError extends Error {
  private readonly _name!: string;
  private readonly _statusText!: string;
  private readonly _status!: number;

  constructor(name: string, statusText: string, status = 500) {
    super(statusText);
    this._name = name;
    this._statusText = statusText;
    this._status = status;
  }

  public get name() {
    return this._name;
  }
  public get message() {
    return this._statusText;
  }
  public get status() {
    return this._status;
  }

  public static gqlErrToPluginError(error: any) {
    return new PluginError('GQLError', JSON.stringify(error, null, 2), 400);
  }
}
