import { Entity } from '@backstage/catalog-model';

export const DOMAINS = 'cloudflare.com/domains';
export const useServiceEntityAnnotations = (entity: Entity) => {
  const domainNames = entity?.metadata.annotations?.[DOMAINS];
  let domains: string[] = [];
  if (domainNames) {
    domains = domainNames.split(',').map(el => el.trim());
  }
  return {
    domains,
  };
};

export const isFlushcacheAvailable = (entity: Entity) =>
  entity?.metadata.annotations?.[DOMAINS];