import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

export const sharedComponentsPlugin = createPlugin({
  id: 'shared-components',
});

export const PaginationActions = sharedComponentsPlugin.provide(
  createComponentExtension({
    name: 'PaginationActions',
    component: {
      lazy: () => import('./components/PaginationActions').then(m => m.PaginationActions),
    }
  }),
);
