import { createApiRef } from '@backstage/core-plugin-api';

export class GroupPanelApiRef {
  constructor(public url: string) {}
    
  async getGroups() {
    const item = window.localStorage.getItem('KhatuToken');
    const query = `
      query GROUP_LIST($full_path:String!) {
        groups(full_path:$full_path) {
          pageInfo{
            endCursor
            hasNextPage
          }
          node{
            _id
            gitlab_id
            name
            full_path
            group_status
            min_access_to_grant
          }
        }
      }
    `;

    const variables = {
      full_path: 'naranja-digital'
    };

    const requestUser = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`
      },
      body: JSON.stringify({ query, variables })
    };
    const response = await fetch(this.url, requestUser);
    if (!response.ok) {
      throw new Error('error HTTP');
    }
    
    const responseBody = await response.json();
    return responseBody;
  }

  async getGroup(_id: string) {
    const item = window.localStorage.getItem('KhatuToken');
    const query = `
      query GROUP_BY_ID($_id: ID!) {
        group(_id: $_id) {
          _id
          owner_email
          name
          access_management_emails
        }
      }
    `;

    const variables = {
      _id: _id
    };

    const requestUser = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`
      },
      body: JSON.stringify({ query, variables })
    };
    const response = await fetch(this.url, requestUser);
    if (!response.ok) {
      throw new Error('error HTTP');
    }
    
    const responseBody = await response.json();
    return responseBody;
  }

  async getMembers(groupId: string) {
    const item = window.localStorage.getItem('KhatuToken');
    const query = `
      query MEMBERS_BY_GROUP($group_id: ID!) {
        members(group_id: $group_id){
          pageInfo{
            endCursor
            hasNextPage
          }
          node{
            _id
            created_at
            expired_at
            gitlab_group_id
            gitlab_group_path
            granted_access_level
            granted_by
            group{
              _id
            }
            process_status
            request_details
            requested_access_level
            updated_at
            user{
              email
            }
          }
        }
      }
    `;

    const variables = {
      group_id: groupId
    };

    const requestUser = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`
      },
      body: JSON.stringify({ query, variables })
    };
    const response = await fetch(this.url, requestUser);
    if (!response.ok) {
      throw new Error('error HTTP');
    }

    const responseBody = await response.json();
    return responseBody;
  }

  async updateGroup(groupId: string, ownerEmail: string, accessEmails: string[]) {
    const item = window.localStorage.getItem('KhatuToken');
    const mutation = `
      mutation ADMIN_UPDATE_GROUP($params: AdminUpdateGroupInput!){
        adminUpdateGroup(params:$params){
          _id
          name
          owner_email
          access_management_emails
        }
      }
    `;
  
    const variables = {
      params: {
        group_id: groupId,
        owner_email: ownerEmail,
        access_management_emails: accessEmails
      }
    };
  
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`,
      },
      body: JSON.stringify({ query: mutation, variables }),
    };
  
    const response = await fetch(this.url, request);
    if (!response.ok) {
      throw new Error('Error HTTP');
    }
    const responseBody = await response.json();
    return responseBody;
  }

  async addMember(groupId: string, userId: string, accessLevel: number) {
    const item = window.localStorage.getItem('KhatuToken');
    const mutation = `
      mutation ADD_MEMBER($params: AdminAddGroupMemberInput!){
        adminAddGroupMember(params:$params){
          _id
          process_status
          user{
            email
          }
        }
      }
    `;
    
    const variables = {
      params: {
        group_id: groupId,
        user_id: userId,
        access_level_value: accessLevel
      }
    };
    
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`,
      },
      body: JSON.stringify({ query: mutation, variables }),
    };
    
    const response = await fetch(this.url, request);
    if (!response.ok) {
      throw new Error('Error HTTP');
    }
    const responseBody = await response.json();
    return responseBody;
  }

  async removeMember(memberId: string) {
    const item = window.localStorage.getItem('KhatuToken');
    const mutation = `
      mutation REMOVE_MEMBER($memberId: ID!){
        adminRemoveGroupMember(member_id: $memberId)
      }
    `;
    
    const variables = {
      memberId: memberId
    };
  
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${item}`,
      },
      body: JSON.stringify({ query: mutation, variables }),
    };
    
    const response = await fetch(this.url, request);
    if (!response.ok) {
      throw new Error('Error HTTP');
    }
    const responseBody = await response.json();
    return responseBody;
  }
}

export const groupPanelApiRef = createApiRef<GroupPanelApiRef>({
  id: 'plugin.group-panel.service',
});
