import { createPermission } from '@backstage/plugin-permission-common';

export const awsAccountsBillingPermission = createPermission({
  name: 'aws.accounts.billing.update',
  attributes: { action: 'update' },
});

export const awsAccountsCloudopsPermission = createPermission({
  name: 'aws.accounts.cloudops.update',
  attributes: { action: 'update' },
});

export const awsAccountsSecurityPermission = createPermission({
  name: 'aws.accounts.security.update',
  attributes: { action: 'update' },
});

export const restartpodsPermission = createPermission({
  name: 'restartpods.access',
  attributes: { action: 'update' },
});

export const adminPanelAccessPermission = createPermission({
  name: 'admin.panel.access',
  attributes: { action: 'update' },
});

export const awsAccountsEntityPermissions = {
  awsAccountsBillingPermission: awsAccountsBillingPermission,
  awsAccountsCloudopsPermission: awsAccountsCloudopsPermission,
  awsAccountsSecurityPermission: awsAccountsSecurityPermission,
  adminPanelAccessPermission: adminPanelAccessPermission,
  restartpodsPermission: restartpodsPermission
};
