import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {
  RdsQueryRunnerApi,
  rdsQueryRunnerApiRef,
} from './api/RdsQueryRunnerApiRef';

export const rdsQueryRunnerPlugin = createPlugin({
  id: 'rds-query-runner',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: rdsQueryRunnerApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        identityApi: identityApiRef,
      },
      factory({ discoveryApi, identityApi }): RdsQueryRunnerApi {
        return new RdsQueryRunnerApi({ discoveryApi, identityApi });
      },
    }),
  ],
});

export const RdsQueryRunnerPage = rdsQueryRunnerPlugin.provide(
  createRoutableExtension({
    name: 'RdsQueryRunnerPage',
    component: () =>
      import('./components/RdsQueryRunnerPage').then(m => m.RdsQueryRunnerPage),
    mountPoint: rootRouteRef,
  }),
);
