import React from 'react';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { ComponentPage } from './pages/ComponentPage';
import { ApiPage } from './pages/ApiPage';
import { GroupPage } from './pages/GroupPage';
import { UserPage } from './pages/UserPage';
import { SystemPage } from './pages/SystemPage';
import { DomainPage } from './pages/DomainPage';
import { GenericEntityPage } from './pages/GenericEntityPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')}>
      {ComponentPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('api')} children={<ApiPage />} />
    <EntitySwitch.Case if={isKind('group')} children={<GroupPage />} />
    <EntitySwitch.Case if={isKind('user')} children={<UserPage />} />
    <EntitySwitch.Case if={isKind('system')} children={<SystemPage />} />
    <EntitySwitch.Case if={isKind('domain')} children={<DomainPage />} />
    {/* 
      Render a GenericEntityPage when the Entity is a:
       - Template
       - Resource
       - Location
      */}
    <EntitySwitch.Case children={<GenericEntityPage />} />
  </EntitySwitch>
);
